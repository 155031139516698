@import url("./erode.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  input[type="radio"].radio-cornflowerBlue {
    accent-color: "#5990BF";
  }
}
